export default {
  namespaced: true,
  state: {
    enable: false,
    visible: false,
    unReadCount: 0
  },
  getters: {
    GET_ENABLE(state, a, store, getters) {
      const magazine = getters['micro/magazine/MAGAZINE_INFO']
      /*const ids = [
        '4552f902de0c4e339a6e0f09ab6ee8d1', // 开发专用测试
        '1fe326b4692e4584be0a959c4729e98f', // Dzsw110804
        'fa2bab216a124e11bb264b4ed8d6d2be', // D组资源发布
        '8d9fecb3d87e44c99e66414575e546e6' // 方正学术出版
      ]

      return ids.indexOf(magazine?.magazineId) > -1*/
      return magazine?.wxMsgAttr == '1'
    }
  },
  actions: {
    async SET_VISIBLE({ state }, visible) {
      if (visible === undefined) {
        state.visible = !state.visible
      } else state.visible = visible
    },
    async SET_UNREAD_COUNT({ state }, count) {
      state.unReadCount = count
    }
  }
}
