import { registerMicroApps } from 'qiankun'

export const apps = [
  {
    name: 'workBench',
    entry: '/workbench',
    // container: '#container',
    activeRule: '/workbench',
    props: {
      routeBase: '/workbench'
    },
    centerKey: 'workBench',
    centerName: '工作台'
  },
  {
    name: 'journal-mobile',
    // entry: '',
    entry: 'http://localhost:5173/',
    container: '#container',
    activeRule: '/service/editing-mobile/simple/journal-work',
    props: {
      routeBase: '/service/editing-mobile/'
    },
    centerKey: 'journalMobileWorkbench',
    centerName: '移动端工作台'
  },
  {
    name: 'editing-app',
    entry: 'http://web-mag-edit.dev6.fzyun.io',
    container: '#container',
    activeRule: '/service/editing',
    props: {
      routeBase: '/service/editing'
    },
    centerKey: 'editingApp',
    centerName: '编审中心'
  },
  {
    name: 'productCenter',
    entry: '//localhost:5703',
    // entry:
    //   sessionStorage.getItem('productGroup') === 'B'
    //     ? 'http://pmf-production-b.dev6.fzyun.io'
    //     : sessionStorage.getItem('productGroup') === 'C'
    //     ? 'http://pmf-production-c.dev6.fzyun.io'
    //     : sessionStorage.getItem('productGroup') === 'D'
    //     ? 'http://pmf-production.dev6.fzyun.io'
    //     : 'http://pmf-production.dev6.fzyun.io',
    // entry: 'http://pmf-production.dev6.fzyun.io',
    container: '#container',
    activeRule: '/service/productCenter',
    props: {
      routeBase: '/service/productCenter'
    },
    centerKey: 'productCenter',
    centerName: '出版中心'
  },
  {
    name: 'resource',
    entry: '//localhost:5704',
    // entry: 'http://pmf-resource.dev6.fzyun.io',
    container: '#container',
    activeRule: '/service/rcapi',
    props: {
      routeBase: '/service/rcapi'
    },
    centerKey: 'rcapi',
    centerName: '资源中心'
  },
  {
    name: 'academic',
    entry: '//localhost:5705',
    // entry: 'http://pmf-academic.dev6.fzyun.io',
    container: '#container',
    activeRule: '/service/academic',
    props: {
      routeBase: '/service/academic'
    },
    centerKey: 'editorBigData',
    centerName: '大数据中心'
  },
  {
    name: 'publish',
    entry: '//localhost:5702',
    // entry: 'http://pmf-publish.dev6.fzyun.io',
    container: '#container',
    activeRule: '/service/publish',
    props: {
      routeBase: '/service/publish'
    },
    centerKey: 'publishPlatformOpen',
    centerName: '发布中心'
  },
  {
    name: 'increment-service',
    entry: '//localhost:5700',
    // entry: 'http://pmf-increment.dev6.fzyun.io',
    container: '#container',
    activeRule: '/service/precisionPush',
    props: {
      routeBase: '/service/precisionPush'
    },
    centerKey: 'precisionPush',
    centerName: '主动传播'
  },
  {
    name: 'app',
    entry: '//localhost:5706',
    container: '#container',
    activeRule: '/service/app',
    props: {
      routeBase: '/service/app'
    },
    centerKey: 'appCenter',
    centerName: '应用中心'
  },
  {
    name: 'influence-monitor',
    entry: '//localhost:5707',
    container: '#container',
    activeRule: '/service/influence-monitor',
    props: {
      routeBase: '/service/influence-monitor'
    },
    centerKey: 'influenceCenter',
    centerName: '影响力监控'
  },
  {
    name: 'management',
    entry: '//localhost:5701',
    // entry: 'http://pmf-management.dev6.fzyun.io',
    container: '#container',
    activeRule: '/service/management',
    props: {
      routeBase: '/service/management'
    },
    centerKey: 'management',
    centerName: '管理中心'
  }
]

export function register(module, store) {
  apps.forEach((app) => {
    app.props['module'] = module
  })

  registerMicroApps(resetEntries(apps), {
    beforeLoad() {
      store.commit('micro/UPDATE_LOADING', true)
    },
    afterMount() {
      setTimeout(() => {
        store.commit('micro/UPDATE_LOADING', false)
      }, 400)
    }
  })
  // 禁止apps被改变了
  Object.freeze(apps)
}

/**
 * 上线后，需要修改 entry 中地址
 * @param apps
 * @returns {*}
 */
function resetEntries(apps) {
  if (process.env.NODE_ENV !== 'production') {
    return apps
  }
  return apps.map((item) => {
    item.name =
      item.name === 'productCenter'
        ? sessionStorage.getItem('productGroup') === 'B'
          ? 'productCenterB'
          : sessionStorage.getItem('productGroup') === 'C'
          ? 'productCenterC'
          : sessionStorage.getItem('productGroup') === 'E'
          ? 'productCenterE'
          : sessionStorage.getItem('productGroup') === 'F'
          ? 'productCenterF'
          : item.name
        : item.name
    // console.log(item.name, window.__micro__apps__[item.name])
    // 工作台不需要配置服务地址
    if (!window.__micro__apps__[item.name] && item.name !== 'workBench') {
      console.error(item.name + ' 服务的地址没有配置！')
    }
    const debugEntry = sessionStorage.getItem('debug_' + item.name)
    return {
      ...item,
      entry: debugEntry || window.__micro__apps__[item.name]
    }
  })
}
