// 资源库
export default {
  name: 'resourceLibrary',
  commonKeyWords: {
    url: '/rc-api/common/keywords'
  }, // 论文标引关键词查询
  addTag(docLibId) {
    return '/rc-api/common/' + docLibId + '/addTag'
  },
  paperIndex(docLibId) {
    return '/rc-api/common/' + docLibId + '/index'
  }, // 论文标引提交接口,
  commonResource(library, resource) {
    return '/rc-api/common/' + library + '/' + resource
  }, // 资源详情
  thesesOrganizations(library, resource) {
    return '/rc-api/common/' + library + '/' + resource + '/organizations'
  }, // 相关机构
  thesesFund(library, resource) {
    return '/rc-api/common/' + library + '/' + resource + '/funds'
  }, // 相关基金
  thesesDetails(library, resource) {
    return '/rc-api/common/' + library + '/' + resource + '/theses'
  }, // 相关论文
  // 获取分类
  getSubCats: {
    url: '/rc-api/common/categories'
  },
  // 获取关键词
  getkeyWords: {
    url: '/rc-api/common/keywords'
  },
  // 获取附件类型
  attachTypes: {
    url: '/rc-api/common/attachTypes'
  },
  // 获取附件标引关键词
  ownKeyWords(docLibId, docId) {
    return `/rc-api/common/${docLibId}/${docId}/ownKeyWords`
  },
  // 上传附件论文列表
  getThesisList: {
    url: '/rc-api/common/getThesisList'
  },
  // 资源发表文章
  thesesList(docLibId, docId) {
    return `/rc-api/common/${docLibId}/${docId}/thesesDetail`
  },
  // 资源的组内其他记录
  groupResource(docLibId, docId, destDocLibCode) {
    return `/rc-api/common/groups/${docLibId}/${docId}/${destDocLibCode}`
  },
  // 删除资源
  deleteSource(docLibId) {
    return `/rc-api/common/deleteDocs/${docLibId}`
  },
  updateDoclib: {
    url: '/rc-api/common/updateDoclib'
  },
  // 获取地区
  getArea: {
    url: '/rc-api/common/getRegion'
  },
  // 资源导出
  exportData: {
    url: '/rc-api/common/books/export'
  },
  // 视频音频文档库下载
  downLoadFile: {
    url: '/rc-api/common/downLoadFile'
  },
  // 刊期整期目录上传
  uploadCatalog(docLibId, docId, other) {
    return `/rc-api/common/${docLibId}/${docId}/uploadFile${other || ''}`
  },
  // XX相关XX
  relations(docLibId, docId, destDocLibId, num) {
    return `/rc-api/common/relations/${docLibId}/${docId}/${destDocLibId}/${num}`
  },
  // 根据索引获取资源详情（没有id）
  detailByName(sourceDocLibId, sourceDocId, destDocLibId, index) {
    return `/rc-api/common/detailByName/${sourceDocLibId}/${sourceDocId}/${destDocLibId}/${index}`
  },

  // 重点学科
  clcCategory(docLibId, docId) {
    return `/rc-api/common/clcCategory/${docLibId}/${docId}`
  },
  // 发文量统计
  articleStatistics(docLibId, docId) {
    return `/rc-api/common/articleStatistics/${docLibId}/${docId}`
  },
  template: {
    url: '/rc-api/common/template'
  },
  download: {
    url: '/rc-api/common/importFailRecord/download'
  },

  // 获取播放次数
  addPlayCount: {
    url: '/rc-api/common/addPlayCount'
  },
  // 批量导入进度查询
  importProcessCheck: {
    url: '/rc-api/common/importProcessCheck'
  },
  // 获取所有批量导入任务
  getImportTask: {
    url: '/rc-api/common/getImportTask'
  },
  authorgetImportTask: {
    url: '/rc-api/authorLibs/getImportTask'
  },
  // 批量导入任务操作
  importOperation: {
    url: '/rc-api/common/importOperation'
  },
  // 批量导入任务操作
  importOperations: {
    url: '/rc-api/periodicals/importOperation'
  },
  // 获取履历信息
  resume(docId) {
    return `/rc-api/authorLibs/resume/${docId}`
  },
  getAttachList(docLibId, docId) {
    return `/rc-api/common/getAttachList/${docLibId}/${docId}`
  },
  getMediaImageByFileRecordID: {
    url: '/rc-api/videoLibs/getMediaImageByFileRecordID'
  },
  detailByTopic(docLibId) {
    return `/rc-api/common/${docLibId}/detailByTopic`
  },
  // 视频上传封面
  uploadCover: {
    url: '/rc-api/common/uploadCover'
  },
  //  获取封面图片的名称
  getCoverInfo: {
    url: '/rc-api/common/getCoverInfo'
  },
  // 历史推送列表
  pushHistoryList: {
    url: '/rc-api/push/pushHistoryList'
  },
  emailTemplateList: {
    url: '/rc-api/push/emailTemplateList'
  },
  getFieldLists: {
    url: '/rc-api/push/getFieldLists'
  },
  sendAuthorList: {
    url: '/rc-api/push/sendAuthorList'
  },
  getEmailTemplateByType: {
    url: '/rc-api/push/getEmailTemplateByType'
  },
  saveOrUpdateEmailTemplate: {
    url: '/rc-api/push/saveOrUpdateEmailTemplate'
  },
  isExist: {
    url: '/rc-api/push/isExist'
  },
  getFullInfo: {
    url: '/rc-api/push/getFullInfo'
  },
  deleteEmailTemplate: {
    url: '/rc-api/push/deleteEmailTemplate'
  },
  operation: {
    url: '/rc-api/periodicals/operation'
  },
  receivers: {
    url: '/rc-api/theses/receivers'
  },
  push: {
    url: '/rc-api/push'
  },
  batchExport: {
    url: '/rc-api/authorLibs/batchExport'
  },
  // 重新生成网刊
  createWebPage: {
    url: '/rc-api/theses/createWebPage'
  },
  // excel 批量导入图片
  importPictures: {
    url: '/rc-api/pictures/import'
  },
  // excel 批量导入表格
  importTables: {
    url: '/rc-api/tables/import'
  }
}
