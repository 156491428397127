/* eslint-disable */
import axios from 'axios'
import Cookies from 'js-cookie'
import { Message } from 'element-ui'

// FIXME 临时测试接口添加cookie认证
// if(process.env.NODE_ENV !== 'production') {
  Cookies.set('default', 'e17e7995-2651-4b7e-9aea-169941267a66')
// }
// 生产平台接口
const PRODUCTION_APIS = [
  'magazine',
  'work',
  'article',
  'book',
  'common',
  'index',
  'userCenter',
  'upload',
  'importArticle',
  'resource',
  'comparisonversion',
  'newUI',
  'huibian',
  'org',
  'tache',
  'multi'
]
// 运营平台magazine为首的APIS
const MAGAZINE_OPERATION_APIS = [
  'magazine/checkparam',
  'magazine/findMagazineById',
  'magazine/update',
  'magazine/gettemplatelist',
  'magazine/saveorupdaterevisionsetting',
  'magazine/getrevisionsettinginfo',
  'magazine/getGroupByMagazineId',
  // 管理中心
  'magazine/getPortalMagazineMenu',
  'magazine/getOrgNewVersion',
  'org/portalUser',
  'multi',
  'multi/article/',
  '/operate/notice'
]
const yyptApiCludes = [
  '/org/portalUser', 
  // '/magazine/getPortalMagazineMenu', 
  // '/magazine/getGroupByMagazineId', 
  '/magazineMessage/',
  '/multi/',
  '/operate/notice',
  '/rc-manager/'
]
const _axios = axios.create({
  baseURL:
    typeof PROJECT_CONFIG !== 'undefined' &&
    typeof PROJECT_CONFIG.BASE_URL !== 'undefined'
      ? PROJECT_CONFIG.BASE_URL
      : '', // api的base_url
  timeout: 600000 // request timeout
  // headers: {'Content-Type': 'multipart/form-data'},
})
const domain = '.' + document.domain.split('.').slice(-2).join('.')

const userInfo = sessionStorage.getItem('userInfo')
  ? JSON.parse(sessionStorage.getItem('userInfo'))
  : null

// 为解决ie11 get请求不刷新页面问题
_axios.interceptors.request.use((config) => {
  config.headers.rcpub = sessionStorage.getItem('orgCode')
  // 给每个接口添加token, 如果token存在，赋值到headers,不能再外面定义，进入请求获取之后再获取token
  if (Cookies.get('commonToken', { path: '/', domain: domain })) {
    config.headers.token = Cookies.get('commonToken', {
      path: '/',
      domain: domain
    })
  }
  // 资源中心统一加刊物id和租户id
  if (config.url.indexOf('rc-api') === 1) {
    // Iszl 该值是为了区分，是否是总览页面，总览页面取当前刊的信息，列表带期刊切换的取切换刊的信息。
    // 如果列表页面没有期刊切换功能，等同于总览页面，需要本地存值Iszl = true，离开当前页面时删除该值
    let Iszl = sessionStorage.getItem('Iszl') === 'true' ? true : false
    let tenantId = Iszl ? sessionStorage.getItem('tenantId') : (sessionStorage.getItem('checktenantId') || sessionStorage.getItem('tenantId'))
    let publicationId = Iszl ? sessionStorage.getItem('publicationId') : (sessionStorage.getItem('checkpublicationId') || sessionStorage.getItem('publicationId'))
    let isGroupMag = Iszl ? sessionStorage.getItem('isGroup') : (sessionStorage.getItem('checkIsGroup') || sessionStorage.getItem('isGroup'))
    let groupVal = JSON.parse(sessionStorage.getItem('isGroup'))
    let checkVal = JSON.parse(sessionStorage.getItem('checkIsGroup'))
    let fromGroup = groupVal ? checkVal ?  false : true : false
    config.url = `${config.url}?publicationId=${publicationId}&tenantId=${tenantId}&isGroupMag=${isGroupMag}&timestamps=${new Date().getTime()}`
    if (!Iszl) {
      config.url = `${config.url}&fromGroup=${fromGroup}`
    }
    if (sessionStorage.getItem('siteId')) {
      config.headers['siteId'] = sessionStorage.getItem('siteId')
    }
  }

  // 发布中心统一加站点id
  if (config.url.indexOf('rc-pub') === 1 && sessionStorage.getItem('siteId')) {
    config.headers['siteId'] = sessionStorage.getItem('siteId')
  }

  for (let api of PRODUCTION_APIS){
    if(config.url.indexOf(api) === 1){
      config.headers['orgCode'] = sessionStorage.getItem('orgCode')
      if(api === 'magazine' || api === 'org' || api === 'multi'){
        for(let oApi of MAGAZINE_OPERATION_APIS){
          if(config.url.indexOf(oApi) !== -1){
            config.headers['orgCode'] = 'magazinemanager'
            config.headers.portal = '77aaf908d3994a95bf4a8e3ca31851ea'
          }
        }
      }
    }
  }
  yyptApiCludes.forEach((item) => {
    if (config.url.indexOf(item) !== -1) {
      config.headers.portal = '77aaf908d3994a95bf4a8e3ca31851ea'
      config.headers.orgCode = 'magazinemanager'
    }
  })
  config.headers.operatorLoginName = userInfo && userInfo.loginName

  // 生产平台加机构code 、生产接口赋值orgCode
  // if (window.location.href.indexOf('productCenter') > 1) {
  //   config.headers['orgCode'] = sessionStorage.getItem('orgCode')

  //   // 数据核校相关接口
  //   if(config.url.indexOf('datacenter') !== -1){
  //     config.headers['orgCode'] = 'magazinemanager'
  //     config.headers.portal = '77aaf908d3994a95bf4a8e3ca31851ea'
  //   }
  // }

  // if (config.url.indexOf('/org/portalUser') !== -1 || config.url.indexOf('/magazine/getPortalMagazineMenu') !== -1) {
  //   config.headers.portal = '77aaf908d3994a95bf4a8e3ca31851ea'
  //   config.headers.orgCode = 'magazinemanager'
  // }


  if (config.method === 'get') {
    // 给get请求增加随机数
    if (config.params) {
      config.params.timestamps = new Date().getTime()
      if (config.params.identifyHeader) {
        config.headers.captcha = config.params.identifyHeader
      }
    } else {
      // 针对没参数的get请求特殊处理
      if (!config.url.indexOf('rc-api') === 1) {
        config.url = config.url + '?timestamps=' + new Date().getTime()
      }
    }
  }
  if (config.method === 'delete') {
    if (config.params && config.params.identifyHeader) {
      config.headers.captcha = config.params.identifyHeader
    }
  }
  return config
})
// respone interceptor
_axios.interceptors.response.use(
  (response) => {
    if (response.data.status === 401) {
      Message({
        message: response.data.message,
        type: 'error',
        duration: 2 * 1000
      })
      return
    } else {
      return response
    }
  },
  (error) => {
    let response = error.response
    // ids 那边token失效时，状态码 307
    if (
      response === 'undefined' ||
      response.status === 307 ||
      response.status === '307' ||
      (response.data && response.data.status === 307)
    ) {
      window.open('/oauth/logout', '_self')
    }
    return Promise.reject(error)
  }
)
var Get = _axios.get
var Post = _axios.post
var Put = _axios.put
var Delete = _axios.delete

export { Get, Post, Put, Delete }
