<template>
  <div style="height: 100%" v-if="subMenuData.length > 0">
    <div
      class="sub-menu-inner"
      v-show="!SUBISCOLLAPSE"
      :class="{ 'is-show': SUBISCOLLAPSE }"
    >
      <div class="sub-menu-out">
        <el-menu
          class="menu-wrapper"
          :default-openeds="defaultOpeneds"
          :default-active="defaultActive"
        >
          <div
            v-for="item in subMenuData"
            :key="item.id"
            :id="`sub-menu-${item.id}`"
          >
            <!-- 二级菜单无子元素 -->
            <el-menu-item
              v-if="item.children.length == 0"
              :index="item.id.toString()"
              :id="item.idText"
              @click.native.stop="menuChildClick(item)"
              :class="currentChecked == item.id ? 'actived' : ''"
            >
              <span
                class="menu-item-title"
                :id="`sub-menu-title-${item.id}`"
                :title="item.name || item.enName"
              >
                {{ item.name || item.enName }}
              </span>
              <span v-if="item.enable != '1'" class="span-disabled"></span>
            </el-menu-item>
            <!-- 二级菜单有子元素 -->
            <el-submenu
              v-else
              class="two-el-submenu"
              :index="item.id.toString()"
              :id="item.idText"
              @click.native.stop="checkSub(item, $event)"
              :class="currentChecked == item.id ? 'actived' : ''"
            >
              <template slot="title">
                <span
                  class="menu-item-title"
                  :id="`sub-menu-title-${item.id}`"
                  :title="item.name || item.enName"
                >
                  {{ item.name || item.enName }}
                </span>
                <span v-if="item.enable !== '1'" class="span-disabled"></span>

                <span
                  class="relate-icon-arrow"
                  @click.stop="changeOpeneds(item)"
                >
                  <img
                    :src="
                      activeIndexRelate == item.id ? iconArrowActive : iconArrow
                    "
                  />
                </span>
              </template>
              <!-- 三级菜单 -->
              <div v-for="child in item.children" :key="child.id">
                <el-submenu
                  class="three-el-submenu"
                  v-if="child.children?.length"
                  :index="child.id.toString()"
                  :id="item.idText"
                  @click.native.stop="checkSub(child, $event)"
                  :class="currentChecked == child.id ? 'actived-two' : ''"
                >
                  <template slot="title">
                    <span
                      class="menu-item-title"
                      :id="`sub-menu-title-${child.id}`"
                      :title="child.name || child.enName"
                    >
                      {{ child.name || child.enName }}
                    </span>
                    <span
                      v-if="child.enable !== '1'"
                      class="span-disabled"
                    ></span>

                    <span
                      class="relate-icon-arrow"
                      @click.stop="changeOpeneds(child)"
                    >
                      <img
                        :src="
                          activeIndexRelate == child.id
                            ? iconArrowActive
                            : iconArrow
                        "
                      />
                    </span>
                  </template>
                  <el-menu-item
                    :class="item.children.length ? 'three-el-menu_item' : ''"
                    v-for="childItem in child.children"
                    :key="childItem.id"
                    :index="childItem.parentId + '-' + childItem.id"
                    :id="childItem.idText"
                    @click.native.stop="menuChildClick(childItem)"
                  >
                    <div>
                      <span
                        class="sub-menu-title"
                        :title="childItem.name || childItem.enName"
                      >
                        {{ childItem.name || childItem.enName }}
                      </span>
                      <span
                        v-if="childItem.enable != '1'"
                        class="span-disabled"
                      ></span>
                    </div>
                  </el-menu-item>
                </el-submenu>
                <el-menu-item
                  v-else
                  class="two-el-menu_item"
                  :index="child.parentId + '-' + child.id"
                  :id="child.idText"
                  @click.native.stop="menuChildClick(child)"
                >
                  <div>
                    <span
                      class="sub-menu-title"
                      :title="child.name || child.enName"
                    >
                      {{ child.name || child.enName }}
                    </span>
                    <span
                      v-if="child.enable != '1'"
                      class="span-disabled"
                    ></span>
                  </div>
                </el-menu-item>
              </div>
            </el-submenu>
          </div>
        </el-menu>
      </div>
      <div class="sub-menu_bottom">
        <div v-if="isHandle" class="opera-area">
          <span class="opreate-title">自定义管理</span>
          <div class="opreate-content">
            <span
              v-for="itemH in handleData"
              v-show="itemH.isShow"
              :key="itemH.key"
              :id="itemH.key"
              :data-track="
                '发布中心-站点管理-频道管理-首页-' + itemH.text + '按钮'
              "
              :title="itemH.text"
              :class="['handle-item', itemH.key]"
              @click="handleOpreate(itemH)"
            >
              <img :src="itemH.icon" />
              <!-- <i :class="itemH.key" :title="itemH.text"> </i> -->
            </span>
          </div>
        </div>
        <div class="sub-coll-btn" id="home-menu_sub-coll-btn">
          <img title="收起" :src="collImage" @click="changeCollapse" />
        </div>
      </div>
    </div>
    <div
      v-show="SUBISCOLLAPSE"
      id="home-menu_open-menu"
      class="close_menu_bar"
      @click="changeCollapse"
    >
      <div class="open_menu_title">展开</div>
      <div class="open_menu">
        <img title="展开" :src="collImage" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SubMenu',
  props: {
    subMenuData: {
      type: Array,
      default: () => []
    },
    activeIndexRelate: {
      type: String,
      default: '0'
    },
    subactiveIndexRelate: {
      type: String,
      default: ''
    },
    // 是否有操作区
    isHandle: {
      type: Boolean,
      default: false
    },
    // eslint-disable-next-line vue/require-default-prop
    handleData: {
      type: Array,
      dafault: () => []
    }
  },
  computed: {
    ...mapState('micro/sidebar', {
      SUBISCOLLAPSE: 'subIsCollapse',
      SUBMENUSTATE: 'subMenuState'
    }),
    collImage() {
      if (this.subIsCollapse) {
        return this.collImageRight
      } else {
        return this.collImageLeft
      }
    }
  },
  mounted() {
    this.defaultOpeneds = []
    this.subMenuData.forEach((item) => {
      if (item.children && item.children.length > 0) {
        this.defaultOpeneds.push(item.id.toString())
        item.children.forEach((i) => {
          if (i.children?.length) {
            this.defaultOpeneds.push(i.id.toString())
          }
        })
      }
    })
    let checkValue = JSON.parse(sessionStorage.getItem('checkValue'))
    let currentMenu = JSON.parse(sessionStorage.getItem('currentMenu'))
    let logicData = checkValue || currentMenu
    if (logicData.activeIndexRelate) {
      let subactiveIndexRelate = logicData.subactiveIndexRelate.split('-')
      if (logicData.activeIndexRelate == '0') {
        this.currentChecked = subactiveIndexRelate[1]
        // this.defaultOpeneds.push(subactiveIndexRelate[1])
      } else {
        this.defaultActive = logicData.subactiveIndexRelate
      }
    }
  },
  data() {
    return {
      isCollapse: false,
      isShowRelate: false,
      subIsCollapse: false,
      iconArrow: require('@main/src/assets/img/sidebar/icon-arrow.png'),
      iconArrowActive: require('@main/src/assets/img/sidebar/icon-arrow-down.png'),
      collImageLeft: require('@main/src/assets/img/sidebar/icon-left-arrow.png'),
      collImageRight: require('@main/src/assets/img/sidebar/icon-right-arrow.png'),
      menuDisabled: require('@main/src/assets/img/sidebar/menu-disabled.png'),
      currentChecked: '',
      defaultActive: '',
      defaultOpeneds: [],
      currentOpendState: false
    }
  },
  methods: {
    menuChildClick(data) {
      this.currentChecked = data.id
      this.$emit('handleSubMenu', {
        activeIndexRelate: data.parentId.toString(),
        subactiveIndexRelate: data.parentId + '-' + data.id
      })
      this.$emit('subMenuClick', data)
    },
    checkSub(data) {
      this.currentChecked = ''
      this.defaultActive = data.id.toString()
      if (!this.defaultOpeneds.includes(data.id.toString())) {
        this.defaultOpeneds.push(data.id.toString())
      }
      this.$emit('checkValueChange', {
        activeIndexRelate: data.parentId.toString(),
        subactiveIndexRelate: data.parentId + '-' + data.id
      })
      this.currentChecked = data.id
      this.$emit('subMenuClick', data)
    },
    handleOpreate(...values) {
      this.$emit('handleOpreate', ...values)
    },
    changeCollapse() {
      this.subIsCollapse = !this.subIsCollapse
      this.$store.commit('micro/sidebar/UPDATE_STATE', {
        name: 'subIsCollapse',
        data: this.subIsCollapse
      })
    },
    changeOpeneds(item) {
      let itemId = item.id.toString()
      if (this.defaultOpeneds.includes(itemId)) {
        this.defaultOpeneds = this.defaultOpeneds.filter((item) => {
          return item != itemId
        })
      } else {
        if (!this.defaultOpeneds.includes(itemId)) {
          this.defaultOpeneds.push(itemId)
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/css/var.scss';
.menu-wrapper .el-submenu .el-submenu__title {
  max-width: 163px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 5px;
}
.menu-wrapper .el-submenu.actived .el-submenu__title {
  border-right: 2px solid #4054af;
  background-color: #d8ddf6;
  color: #4054af !important;
}
.sub-menu-inner {
  $submenuBottomH: 266px;
  $operaAreaH: 180px;
  height: 100%;
  min-height: 400px;
  overflow-y: hidden;
  background: #f9f9f9;
  // position: relative;
  .actived {
    .el-menu-item {
      color: #4054af;
    }
  }
  .menu-wrapper.el-menu {
    margin-bottom: 0;
  }
  .sub-menu_bottom {
    height: $submenuBottomH;
  }
  .menu-item-title {
    position: relative;
    display: inline-block;
    max-width: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .span-disabled {
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    border: 1px solid #404040;
    border-radius: 50%;
    margin-left: 7px;
    &::after {
      content: '';
      width: 14px;
      height: 1px;
      background-color: #404040;
      position: absolute;
      top: 54%;
      transform: rotate(45deg);
    }
  }
  .show-sub-menu {
    color: #404040;
    font-size: 14px;
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .sub-menu-out {
    height: calc(100% - 266px);
    overflow-x: hidden;
    overflow-y: auto;
    // padding-left: 6px;
  }

  &.is-show {
    .menu-wrapper,
    .opera-area {
      display: none;
      height: $operaAreaH;
    }
  }
  .opera-area {
    border-top: 2px solid #ebebeb;
    height: $operaAreaH;
    box-sizing: border-box;
    .opreate-title {
      display: block;
      padding: 15px 20px;
    }
  }

  .opreate-content {
    width: 170px;
    white-space: initial;
    padding: 0 10px;
    .handle-item {
      display: inline-block;
      width: 19px;
      height: 19px;
      line-height: 19px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;
      margin: 5px 9px 15px;
      cursor: pointer;
      &.add {
        display: block;
      }
      i {
        display: inline-block;
        width: 19px;
        height: 19px;
      }
    }
  }

  .sub-coll-btn {
    width: 100%;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 3;
    background-color: transparent;
    position: relative;
    top: 1px;
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      line-height: 50px;
      background-color: #f0f2f5;
      position: absolute;
      bottom: 45px;
      z-index: 0;
    }
    img {
      cursor: pointer;
      width: 46px;
      height: 46px;
      z-index: 3;
    }
  }
}
.menu-wrapper {
  // max-height: 368px;
  height: 100%;
  overflow-y: auto;
  background-color: #f9f9f9;

  // 隐藏默认
  .el-submenu__icon-arrow {
    display: none;
  }
  .el-menu-item {
    font-size: 14px;
    color: #404040;
    background-color: #f9f9f9;
    &.actived {
      background-color: #d8ddf6;
      color: #4054af;
      border-right: 2px solid #4054af;
    }
    max-width: 200px;
  }
  .el-submenu {
    .el-menu-item {
      // &.is-active {
      //   background-color: #fff;
      //   color: #4054af;
      //   border-right: none;
      // }
    }
    .el-submenu__title {
      font-size: 14px;
      background-color: #f9f9f9;
    }
    .relate-icon-arrow {
      padding: 0 10px;
      position: absolute;
      right: 0px;
      top: 0px;
      img {
        width: 12px;
        height: 7px;
      }
    }
    .relate-icon-arrow {
      img {
        transform: rotate(-90deg);
      }
    }
    &.is-opened {
      .el-submenu__title {
        color: #404040;
      }
      .relate-icon-arrow {
        img {
          transform: rotate(0deg);
        }
      }
    }
    &.is-active {
      .el-submenu__title {
        color: #4054af;
        border-right: 2px solid #4054af;
        background-color: #d8ddf6;
      }
    }
    .two-el-menu_item {
      padding-left: 40px !important;
      padding-right: 20px !important;
    }
    .three-el-menu_item {
      padding-left: 50px !important;
      padding-right: 20px !important;
    }
    .el-menu-item {
      font-size: 12px;
      min-width: auto;

      background-color: #fff;
      border-bottom: 1px solid #f0f2f5;

      .sub-menu-title {
        display: inline-block;
        width: 100%;
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:last-child {
        border: none;
      }
      &.is-active {
        color: #4054af;
      }
    }
  }
  .el-submenu {
    .three-el-submenu {
      .el-submenu__title {
        font-size: 12px !important;
        padding-left: 40px !important;
        // background-color: #f9f9f9;
      }
      .relate-icon-arrow {
        img {
          transform: rotate(-90deg);
        }
      }
      &.is-opened {
        .el-submenu__title {
          color: #404040;
        }
        .relate-icon-arrow {
          img {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
.close_menu_bar {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: #f9f9f9;
  z-index: 10;
  width: 50px;
  padding-top: 15px;
  cursor: pointer;
  .open_menu_title {
    height: calc(100% - 86px);
    padding-top: 8px;
    text-align: center;
    font-size: 14px;
    color: #404040;
  }
  .open_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 86px;
    position: relative;
    img {
      width: 38px;
      height: 38px;
      z-index: 3;
      position: relative;
      top: -2px;
    }
    &::after {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      line-height: 50px;
      background-color: #f0f2f5;
      position: absolute;
      bottom: 45px;
      z-index: 0;
    }
  }
}
</style>
